import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Flex,
  Button,
  Text,
  FormLabel,
  Input,
  usePanGesture,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";

import Card from "components/card/Card";
import SelectField from "components/fields/SelectField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/general/useOptions";
import { useDokumenURL } from "hooks/admin/kewangan/useDokumenURL";

interface LocationState {
  nama?: string;
  no_kp_ssm?: string;
  type?: string;
  id?: string;
  user_type?: string;
}

const JanaanBukuTunai = () => {
  const location = useLocation();
  const history = useHistory();
  const { state } = useLocation<LocationState>();
  const searchParams = new URLSearchParams(location.search);
  const { bulanOps, tahunOps } = useOptions();

  const { isLoading, error, dokumenURL } = useDokumenURL("buku-tunai", {
    nama: state?.nama,
    no_kp_ssm: state?.no_kp_ssm,
    bulan: searchParams.get("bulan") || new Date().getMonth() + 1,
    tahun: searchParams.get("tahun") || new Date().getFullYear(),
    syarikat_perniagaan_id: state?.id,
    user_id: state?.id,
    syarikat_perniagaan_type: state?.user_type,
  });

  const [searchValues, setSearchValues] = useState({
    nama: state?.nama,
    no_kp_ssm: state?.no_kp_ssm,
    bulan: searchParams.get("bulan") || new Date().getMonth() + 1,
    tahun: searchParams.get("tahun") || new Date().getFullYear(),
    syarikat_perniagaan_id: state?.id,
    user_id: state?.id,
    syarikat_perniagaan_type: state?.user_type,
  });

  const handleOnTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const removeDefaultParams = () => {
    searchParams.delete("page");
    searchParams.delete("limit");
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const search = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      }
    });

    history.push({ search: searchParams.toString() });
  };

  const reset = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      nama: state?.nama,
      no_kp_ssm: state?.no_kp_ssm,
      bulan: "",
      tahun: "",
      syarikat_perniagaan_id: state?.id,
      user_id: state?.id,
      syarikat_perniagaan_type: state?.user_type,
    });
    document
      .querySelectorAll("select")
      .forEach((select) => (select.value = ""));

    history.push({ search: searchParams.toString() });
  };

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card flexDirection="column" w="100%" p={"28px"}>
        <Flex
          flexDirection={{ base: "column", md: "row" }}
          alignItems="center"
          gap="18px"
          mb={"20px"}
        >
          <FormControl>
            <FormLabel variant="main" mb="8px">
              Nama
            </FormLabel>

            <Input
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              fontWeight="500"
              size="md"
              value={state?.nama}
              readOnly
            />
          </FormControl>

          <FormControl>
            <FormLabel variant="main" mb="8px">
              No. KP/ SSM
            </FormLabel>

            <Input
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              fontWeight="500"
              size="md"
              value={state?.no_kp_ssm}
              readOnly
            />
          </FormControl>
        </Flex>

        <Flex
          flexDirection={{ base: "column", md: "row" }}
          alignItems="center"
          gap="18px"
          mb={"38px"}
        >
          <SelectField
            label={"Bulan"}
            options={bulanOps}
            value={searchValues.bulan}
            onChange={(e: any) => handleOnChange(e, "bulan")}
          />

          <SelectField
            label={"Tahun"}
            options={tahunOps}
            value={searchValues.tahun}
            onChange={(e: any) => handleOnChange(e, "tahun")}
          />

          <Flex gap={2} alignItems="center" alignSelf="flex-end">
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="max-content"
              px="18px"
              onClick={search}
            >
              Jana
            </Button>

            <Button
              fontSize="sm"
              variant="outline"
              fontWeight="500"
              w="max-content"
              onClick={reset}
            >
              Set Semula
            </Button>
          </Flex>
        </Flex>

        <Flex alignItems={"center"} pb={"20px"}>
          {dokumenURL ? (
            <Box
              as="iframe"
              src={dokumenURL.data.url}
              height={"600px"}
              width="100%"
            />
          ) : (
            <Text fontWeight="bold" fontSize={{ sm: "14px" }}>
              Tiada Janaan Buku Tunai
            </Text>
          )}
        </Flex>
      </Card>
    </Box>
  );
};

export default JanaanBukuTunai;
