import React from "react";
import { Box, Text, List, Icon, Spinner, Link } from "@chakra-ui/react";
import { useHistory, NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import {
  MdEditNote,
  MdDelete,
  MdOutlineRemoveRedEye,
  MdAnnouncement,
  MdSend,
} from "react-icons/md";

import ResourceTwo from "components/resourceTwo/ResourceTwo";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useNotifications } from "hooks/setting/notifikasi/useNotifications";
import { usePublishNotification } from "hooks/setting/notifikasi/usePublishNotification";
import { useDeleteNotification } from "hooks/setting/notifikasi/useDeleteNotification";

const columnHelper = createColumnHelper<any>();

const Notification = () => {
  const { isLoading, error, notifikasis } = useNotifications();

  const { isPublishing, publishNotifications } = usePublishNotification();
  const { isDeleting, deleteNotifications } = useDeleteNotification();

  const history = useHistory();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("tajuk_notifikasi", {
      header: () => <Text variant="tableHeading">Tajuk Notifikasi</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("keterangan_notifikasi", {
      header: () => <Text variant="tableHeading">Keterangan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List minW="max-content" spacing={2}>
          <NavLink
            to={`/admin/settings/notification/kemaskini/${info.row.original.id}`}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <NavLink
            to={`/admin/settings/notification/show/${info.row.original.id}`}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>

          {isPublishing ? (
            <Spinner />
          ) : (
            <Link
              onClick={() => publishNotifications({ id: info.row.original.id })}
            >
              <Icon w="24px" h="24px" me="5px" color={"blue.500"} as={MdSend} />
            </Link>
          )}

          {isDeleting ? (
            <Spinner />
          ) : (
            <Link
              onClick={() => deleteNotifications({ id: info.row.original.id })}
            >
              <Icon
                w="24px"
                h="24px"
                me="5px"
                color={"red.500"}
                as={MdDelete}
              />
            </Link>
          )}
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={notifikasis}
        title={"Senarai Notifikasi"}
        createTitle="Tambah Notifikasi"
        onCreate={() => history.push("/admin/settings/notification/baru")}
        allowPagination={false}
      />
    </Box>
  );
};

export default Notification;
